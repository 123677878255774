.logo {
    width: 25%;
  }

.logo1 {
  height: 60px;
  width: 60px;
}
  .nav-theme {
    background-color: #101018f8;
    font-size: 20px;
  }
  
  .animate-navbar {
    box-shadow: 1px 1px 1px #222;
    animation: moveDown 0.5s ease-in-out;
  }
  
  @keyframes moveDown {
    from {
      transform: translateY(-5rem);
    }
    to {
      transform: translateY(0rem);
    }
  }
  