.contact-jumbotron {
    background-image: url("../../assets/imgs/background/background.webp");
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  #youtube-btn {
    border-color: #f83f37;
    color: #f83f37;
  }
  #youtube-btn:hover {
    background-color: #f83f37;
    color: #ffffff;
  }